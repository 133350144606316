<template>
  <div class="container">
    <div class="header text-center">
      <h2>Evolve</h2>
    </div>
    <div v-if="loading">
      <loading />
    </div>
    <div v-else>
      <div class="text-center" v-if="evolving">
        <div v-if="!evolved">
          <img
            class="evolution-image evolving"
            :src="`https://api.pootopia.io/asset/${selectedToken}.png?t=${Date.now()}`"
            alt=""
            srcset=""
          />
          <div>Evolving</div>
        </div>
        <div v-else>
          <img
            :style="{
              backgroundImage: backgroundUrl,
            }"
            class="evolution-image"
            :src="`/static/evolution/${evolutionClass}${evolutionNextlevel}.png`"
            alt=""
            srcset=""
          />
          <div>
            Please refresh your metadata on Opensea to see the evolved pootopia
          </div>
          <button @click="evolveAnother" class="btn">Evolve another</button>
        </div>
      </div>
      <div v-else>
        <div class="token-wrapper box my-3">
          <div v-for="token in tokens" :key="token">
            <evolve-card
              :id="token"
              :selected="selectedToken === token"
              @click="selectEvolve(token)"
            />
          </div>
        </div>
        <div v-if="selectedToken !== null" class="box p-3">
          <div class="evolution-wrapper">
            <div class="d-flex justify-content-center">
              <div
                class="compare-image"
                :style="{
                  backgroundImage: backgroundUrl,
                }"
              >
                <img
                  :src="`https://api.pootopia.io/asset/${selectedToken}.png?t=${Date.now()}`"
                  alt=""
                  srcset=""
                />
              </div>
              <div
                class="mx-2 d-flex justify-content-center align-items-center"
              >
                >
              </div>
              <div
                class="compare-image"
                :style="{
                  backgroundImage: backgroundUrl,
                }"
              >
                <img
                  :src="`/static/evolution/${evolutionClass}${evolutionNextlevel}.png`"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
          <div class="text-center mt-3">
            Price:
            <img
              class="ml-2"
              src="/static/images/flush-token-golden.png"
              alt=""
            />{{ price }}
          </div>
          <div class="text-center">
            <button @click="evolve" class="btn">Evolve</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapState } from "vuex";
import Loading from "./Loading.vue";
import StakingContractService from "../services/StakingContractService";
import EvolveContractService from "../services/EvolveContractService";
import EvolveCard from "./EvolveCard.vue";
import { formatBalance } from "../utils/wallet.helper";
export default {
  components: { Loading, EvolveCard },
  data() {
    return {
      loading: true,
      evolving: false,
      tokens: [],
      selectedToken: null,
      evolutionClass: "",
      evolutionlevel: 0,
      evolutionNextlevel: 0,
      nextLevelPrice: 0,
      background: "",
      evolved: false,
    };
  },
  computed: {
    ...mapState(["accountAddress", "totalSupply"]),
    ...mapGetters(["isConnected"]),
    backgroundUrl() {
      return `url('/static/evolution/Background-${this.background}-12.png')`;
    },
    price() {
      return +formatBalance(+this.nextLevelPrice);
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      Promise.all([
        axios.get(`${this.config.apiUrl}account/poops/${this.accountAddress}`),
        StakingContractService.stakedTokensOfOwner(
          this.config.mainContractAddress,
          this.accountAddress
        ),
      ])
        .then((e) => {
          this.tokens = e[0].data.concat(e[1]);
        })
        .catch((e) => {
          this.tokens = [];
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectEvolve(token) {
      this.loading = true;
      this.selectedToken = token;
      Promise.all([
        axios.get(
          `https://api.pootopia.io/asset/${token}?t=${Date.now()}`
        ),
        EvolveContractService.nextLevelPrice(token),
      ])
        .then(([metaData, nextLevelPrice]) => {
          this.evolutionClass = metaData.data.attributes[1].value.replace(
            /[^a-zA-Z]+/g,
            ""
          );
          const tempLevel = +metaData.data.attributes[1].value.replace(
            /[^0-9]/g,
            ""
          );
          console.log(this.tempLevel);
          this.evolutionLevel = tempLevel <= 6 ? 6 : tempLevel;
          console.log(this.evolutionLevel);
          this.evolutionNextlevel = this.evolutionLevel + 1;
          this.background = metaData.data.attributes[0].value;
          this.nextLevelPrice = nextLevelPrice;
          this.showmodal = true;
          this.loading = false;
        })
        .catch((e) => {
          console.log(e);
          this.selectedToken = null;
          this.loading = false;
        });
    },
    evolve() {
      if (
        !confirm(
          `Are you sure you want to spend ${this.price} FLUSH to evolve?`
        )
      )
        return;
      this.evolving = true;
      EvolveContractService.evolve(this.selectedToken, this.accountAddress)
        .then(() => {
          this.evolved = true;
          this.loadData();
        })
        .catch((err) => {
          this.evolving = false;
          console.log(err.message);
          this.$toast.error(err.message);
        });
    },
    evolveAnother() {
      this.evolving = false;
      this.evolved = false;
      this.selectedToken = null;
      this.loadData();
    },
  },
  mounted() {
    if (this.isConnected) {
      this.loadData();
    }

    this.$watch("accountAddress", () => {
      if (this.isConnected) {
        this.loadData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.token-wrapper {
  height: 320px;
  overflow-x: scroll;
  display: flex;
}

.evolution-wrapper {
  text-align: center;
  align-items: center;
  font-size: 100px;
}

.evolution-image {
  width: 45%;
  max-width: 200px;
  border: 5px solid var(--color-alternative);
}

.compare-image {
  width: 45%;
  max-width: 200px;
  border: 5px solid var(--color-alternative);
  > img {
    width: 100%;
  }
}

.box {
  border: 3px solid #1e0f02;
}

@media screen and (max-width: 768px) {
  .token-wrapper {
    overflow-x: hidden;
    display: block;

    > div {
      display: flex;
      justify-content: center;
    }
  }
}

@keyframes evolving {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
    opacity: 0.8;
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
    opacity: 1;
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
    opacity: 1;
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
    opacity: 0.8;
  }
}

.evolving {
  -webkit-animation: evolving 2s infinite ease-in-out;
  -o-animation: evolving 2s infinite ease-in-out;
  -ms-animation: evolving 2s infinite ease-in-out;
  -moz-animation: evolving 23s infinite ease-in-out;
  animation: evolving 2s infinite ease-in-out;
}
</style>
