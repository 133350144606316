<template>
  <div class="container my-2">
    <h2 class="text-center mb-4">Staking</h2>
    <div v-if="!loading && approved">
      <div class="row">
        <div
          class="
            col-12 col-md-4
            d-flex
            align-items-center
            justify-content-start
            mobile
          "
        >
          Daily:
          <img
            class="ml-2"
            src="/static/images/flush-token-golden.png"
            alt=""
          />
          {{ sumDailyRewards.toFixed(2) }}
        </div>
        <div class="col-12 col-md-8">
          <div class="row">
            <div
              class="
                col-12 col-md-6
                d-flex
                align-items-center
                justify-content-end
                mobile
              "
            >
              Total:
              <img
                class="ml-2"
                src="/static/images/flush-token-golden.png"
                alt=""
              />{{ totalRewards }}
            </div>
            <div
              class="
                col-12 col-md-6
                d-flex
                align-items-center
                justify-content-end
                mobile
              "
            >
              <button
                @click="claim"
                :disabled="totalRewards === 0"
                class="btn m-2"
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!loading && !approved">
      <div class="p-3">
        <p>Staking Contract must be approved before you can stake.</p>
        <p>This only needs to be done once.</p>
      </div>
      <div class="m-1 d-flex justify-content-center">
        <button @click="approveContract" class="btn">Approve</button>
      </div>
    </div>
    <div v-if="loading" class="d-flex"><loading /></div>
    <div v-if="!loading && approved" class="row wrapper box p-1">
      <div class="col-12 col-sm-6">
        <h5 class="text-center mt-1">Unstaked</h5>
        <div class="token-wrapper">
          <div v-for="token in tokens" :key="token">
            <staking-card
              :id="token"
              :selected="this.tokensToStake?.includes(token)"
              @click="addTokenToListStake(token)"
            />
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button
            @click="stakeList"
            :disabled="this.tokensToStake.length === 0"
            class="btn mt-2"
          >
            Stake Tokens
          </button>
        </div>
      </div>

      <div class="col-12 col-sm-6 staked">
        <h5 class="text-center mt-1">Staked</h5>
        <div class="token-wrapper">
          <div v-for="token in stakedTokens" :key="token">
            <staking-card
              :id="token"
              :selected="this.tokensToUnstake?.includes(token)"
              @click="addTokenToListUnstake(token)"
            />
          </div>
        </div>
        <div v-if="!loading" class="d-flex justify-content-center">
          <button v-if="totalRewards > 0" @click="claim" class="btn m-2">
            Withdraw before unstake
          </button>
          <button
            v-else-if="approved"
            @click="unstakeList"
            :disabled="this.tokensToUnstake.length === 0"
            class="btn mt-2"
          >
            Unstake Tokens
          </button>
          <button v-else @click="approveContract" class="btn mt-2">
            Approve Contract
          </button>
        </div>
      </div>
    </div>
    <div v-if="!loading && approved" class="d-flex justify-content-center">
      <div @click="addFlush" class="btn my-2">Add Flush Token</div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import StakingCard from "./StakingCard.vue";
import StakingContractService from "../services/StakingContractService";
import FlushContractService from "../services/FlushContractService";
import MainContractService from "../services/MainContractService";
import { mapGetters, mapState } from "vuex";
import Loading from "./Loading.vue";
export default {
  computed: {
    ...mapState(["accountAddress", "totalSupply"]),
    ...mapGetters(["isConnected"]),
    averageStakedDays() {
      return _.meanBy(this.tokenList, "stakedDays");
    },
    sumDailyRewards() {
      return _.sumBy(this.tokenList, "dailyRewards");
    },
  },
  data() {
    return {
      tokens: [],
      tokensToStake: [],
      stakedTokens: [],
      tokensToUnstake: [],
      approved: true,
      loading: true,
      totalRewards: 0,
      tokenList: [],
    };
  },
  components: { StakingCard, Loading },
  methods: {
    loadData() {
      this.tokens = [];
      this.tokensToStake = [];
      this.stakedTokens = [];
      this.tokensToUnstake = [];

      this.loading = true;
      Promise.all([
        axios.get(`${this.config.apiUrl}account/poops/${this.accountAddress}`),
        StakingContractService.stakedTokensOfOwner(
          this.config.mainContractAddress,
          this.accountAddress
        ),
      ])
        .then(([availableTokensList, stakedTokensList]) => {
          this.stakedTokens = stakedTokensList;
          this.tokens = availableTokensList.data.filter(
            (token) => stakedTokensList.includes(token) === false
          );
          this.loading = false;
        })
        .catch(() => {
          this.loadData();
        });
    },
    async checkApproval() {
      await MainContractService.isApprovedForAll(
        this.accountAddress,
        this.config.stakingContractAddress
      ).then((isApproved) => {
        this.approved = isApproved;
      });
    },
    async approveContract() {
      this.loading = true;
      MainContractService.setApproval(
        this.accountAddress,
        this.config.stakingContractAddress
      )
        .then(() => {
          this.approved = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addTokenToListStake(id) {
      if (this.tokensToStake.includes(id)) {
        this.tokensToStake.splice(this.tokensToStake.indexOf(id), 1);
      } else {
        this.tokensToStake.push(id);
      }
    },

    addTokenToListUnstake(id) {
      if (this.tokensToUnstake.includes(id)) {
        this.tokensToUnstake.splice(this.tokensToUnstake.indexOf(id), 1);
      } else {
        this.tokensToUnstake.push(id);
      }
    },
    stakeList() {
      this.loading = true;
      StakingContractService.stake(this.accountAddress, [...this.tokensToStake])
        .then(() => {
          this.loadData();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    unstakeList() {
      this.loading = true;
      StakingContractService.unstake(this.accountAddress, [
        ...this.tokensToUnstake,
      ])
        .then(() => {
          this.loadData();
        })
        .catch((e) => {
          console.log(e);
          this.loading = false;
        });
    },
    addFlush() {
      FlushContractService.addFlush();
    },
    async claim() {
      this.loading = true;
      const request = await axios.get(
        `${this.config.apiUrl}rewards/request/${this.accountAddress}`
      );

      if (!request) {
        this.loading = false;
        return;
      }
      StakingContractService.withdraw(
        this.accountAddress,
        request.data.amount,
        request.data.signature
      )
        .then(() => this.getRewardsInfo())
        .catch(() => {
          this.loading = false;
        });
    },
    getRewardsInfo() {
      this.loading = true;
      axios
        .get(`${this.config.apiUrl}rewards/pending/${this.accountAddress}`)
        .then(async ({ data }) => {
          this.totalRewards = data.pendingRewards.totalRewards;
          this.tokenList = data.pendingRewards.tokensRewards;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    if (this.isConnected) {
      this.getRewardsInfo();
      this.checkApproval();
      this.loadData();
    }

    this.$watch("accountAddress", () => {
      if (this.isConnected) {
        this.getRewardsInfo();
        this.checkApproval();
        this.loadData();
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.token-wrapper {
  height: 400px;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  border: 3px solid #1e0f02;
}

.box {
  border: 3px solid #1e0f02;
}

@media screen and (max-width: 767px) {
  .staked {
    margin-top: 30px;
  }

  .mobile {
    justify-content: center !important;
    width: 100% !important;
  }
}
</style>
