<template>
  <footer>
    <div class="social">
      <a href="https://twitter.com/PootopiaNFT" target="_blank"
        ><img src="/static/images/medias/footer-twitter.svg" alt="" /></a
      ><a href="https://opensea.io/collection/pootopia" target="_blank"
        ><img src="/static/images/medias/footer-opensea.svg" alt="" /></a
      ><a href="https://discord.gg/ypQJnp6tcn" target="_blank"
        ><img src="/static/images/medias/footer-discord.svg" alt=""
      /></a>
    </div>
    <div class="contract">
      <a
        :href="`https://etherscan.io/address/${config.mainContractAddress}#code`"
        target="_blank"
        >Smart Contract</a
      >
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  padding: 0 50px;
  margin-top: 130px;
  display: block;
  margin-bottom: 150px;
  padding-top: 30px;
}

.social {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  > a {
    display: block;
    width: 40px;
    height: 40px;
    background-color: var(--color-primary);
    color: var(--color-primary-font);
    border-radius: 3px;
    text-align: center;
    margin-right: 15px;
    line-height: 35px;
    transition: filter 0.2s;
    transition: all 0.2s ease-in;
    &:hover {
      background-color: var(--color-alternative);
      color: var(--color-alternative-font);
    }
  }
}

.contract {
  margin-left: auto;
  text-align: center;
  margin-top: 35px;
}
</style>
