import web3 from "./Web3Service";
import config from "../config";

export default class BaseContractService {
  constructor(contractAddress, abi) {
    this.contract = new web3.eth.Contract(abi, contractAddress);
  }

  mint(quantity, from) {
    let options = {
      from,
      value: (parseFloat(config.unitPrice) * 10 ** 18 * quantity).toString(),
    };

    return this.contract.methods.mint(quantity).send(options);
  }

  totalSupply() {
    return this.contract.methods.totalSupply().call();
  }

  hasSaleStarted() {
    return this.contract.methods.hasSaleStarted().call();
  }

  balanceOf(accountAddress) {
    return this.contract.methods.balanceOf(accountAddress).call();
  }

  tokenOfOwnerByIndex(accountAddress, index) {
    return this.contract.methods
      .tokenOfOwnerByIndex(accountAddress, index)
      .call();
  }
}
