import BaseContractService from "./BaseContractService";
import abi from "../abi/staking.json";
import config from "../config";
// import web3 from "./Web3Service";
// import { createToast } from "mosha-vue-toastify";
// const toast = (title, description, type) => {
//   createToast(
//     {
//       title: title,
//       description: description,
//     },
//     {
//       type: type,
//       position: "top-center",
//       showIcon: "true",
//     }
//   );
// };

class MainContractService extends BaseContractService {
  constructor() {
    super(config.stakingContractAddress, abi);
  }

  async stakedTokensOfOwner(contractAddress, owner) {
    return this.contract.methods
      .stakedTokensOfOwner(contractAddress, owner)
      .call();
  }

  async stake(from, list) {
    const mintAction = this.contract.methods.stake(
      config.mainContractAddress,
      list
    );

    let options = {
      from,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(e);
        throw { message: e.message };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  async withdraw(from, amount, signature) {
    console.log({ from, amount, signature });
    const mintAction = this.contract.methods.withdrawFlush(amount, signature);

    let options = {
      from,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(e);
        throw { message: e.message };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  async unstake(from, list) {
    const mintAction = this.contract.methods.unstake(
      config.mainContractAddress,
      list
    );

    let options = {
      from,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(e);
        if (e.message.includes("sale is not active")) {
          throw { message: `Sales  has not started yet!` };
        } else {
          throw { message: `Insufficient funds!` };
        }
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }
}

export default new MainContractService();
