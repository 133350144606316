<template>
  <div>
    <section class="green-background">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-12">
            <p class="info mt-5 mb-5">
              Pootopia is designed atop a porcelain throne. Stake your poop and
              see it evolve. Each time it evolves you will earn a multiple of
              $FLUSH. The more evolved your poop, the greater multiple yield of
              $FLUSH earned. Once un-staked your $FLUSH has a 10% chance of
              being flushed and burned along with your poop.
            </p>
          </div>
          <div class="col-lg-4 col-12">
            <div class="showcase">
              <div class="showcase-image mt-5">
                <img src="/static/images/poo-gif.gif" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section green-background">
      <staking />
    </section>
    <section class="section green-background">
      <evolve />
    </section>
    <section class="section green-background">
      <evolution />
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Staking from "../components/Staking.vue";
import Evolution from "../components/Evolution.vue";
import Evolve from "../components/Evolve.vue";
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      timerInt: {},
      timer: "",
      timerTitle: "",
      timerNextSale: " ",
      saleStarted: false,
    };
  },
  components: { Staking, Evolution, Evolve },
  computed: {
    ...mapState(["totalSupply"]),
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.section {
  margin-top: 70px;
}

.logo {
  display: flex;
  justify-content: center;
  > img {
    width: 100%;
    max-width: 500px;
    margin: 30px auto;
  }
}

.info {
  line-height: 36px;
  font-size: 1.1em;
  text-align: justify;
  margin: 0 50px;
}

.showcase {
  display: block;
  width: 100%;
  position: relative;
  max-width: 330px;
  margin: 0 auto;

  > .showcase-image {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;

    > img {
      width: 100%;
      height: auto;
      border: 10px solid var(--color-alternative);
    }
  }
}
</style>
