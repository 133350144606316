<template>
  <div class="d-flex justify-content-end wrapper">
    <div class="wallet-wrapper" v-if="!isConnected">
      <div @click="handleConnect" class="d-flex" href="#">
        <div class="led-red"></div>
        Disconnected
      </div>
    </div>
    <div v-else class="wallet-wrapper" @click="handleDisconnect">
      <div class="led-green"></div>
      {{ getAccountAddress }}
    </div>
  </div>
</template>

<script>
import { formatWalletAddress } from "../utils/wallet.helper";
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["accountAddress", "walletBalance", "networkName"]),
    ...mapGetters(["isConnected"]),
    getAccountAddress() {
      return this.isConnected
        ? formatWalletAddress(this.$store.state.accountAddress)
        : "Connect your wallet";
    },
  },
  methods: {
    handleConnect() {
      this.$wallet.connect();
      this.$emit("walletConnected");
    },
    handleDisconnect() {
      this.$wallet.disconnect();
      this.$emit("walletDisconnected");
      window.location.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
  .wrapper {
    justify-content: center !important;
  }
}

.led-red {
  margin: 10px 10px 10px 0;
  width: 12px;
  height: 12px;
  background-color: #ff0606;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #600 0 -1px 9px, #f00 0 2px 12px;
}

.led-green {
  margin: 10px 10px 10px 0;
  width: 12px;
  height: 12px;
  background-color: #690;
  border-radius: 50%;
  box-shadow: #000 0 -1px 7px 1px, inset #460 0 -1px 9px, #7d0 0 2px 12px;
}

.connect-text {
  color: white;
  text-align: right;
  margin-right: 15px;
  margin-top: 5px;
  font-family: "matiz";
}
.wallet-wrapper {
  font-size: 1rem;
  line-height: 30px;
  outline: none;
  background: var(--color-primary);
  color: var(--color-primary-font);
  border-radius: 5px;
  border: none;
  padding: 3px 20px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: var(--color-alternative);
    color: var(--color-alternative-font);
  }
}
</style>
