import BaseContractService from "./BaseContractService";
import abi from "../abi/main.json";
import config from "../config";
import web3 from "./Web3Service";
// import { createToast } from "mosha-vue-toastify";
// const toast = (title, description, type) => {
//   createToast(
//     {
//       title: title,
//       description: description,
//     },
//     {
//       type: type,
//       position: "top-center",
//       showIcon: "true",
//     }
//   );
// };

class MainContractService extends BaseContractService {
  constructor() {
    super(config.mainContractAddress, abi);
  }

  isApprovedForAll(from, address) {
    return this.contract.methods.isApprovedForAll(from, address).call();
  }

  setApproval(from, address) {
    return this.contract.methods.setApprovalForAll(address, true).send({
      from,
    });
  }

  unsetApproval(from, address) {
    return this.contract.methods.setApprovalForAll(address, false).send({
      from,
    });
  }

  async startMint(quantity, from) {
    console.log(
      "start mint",
      (parseFloat(config.unitPrice) * 10 ** 18 * quantity).toString()
    );
    const mintAction = this.contract.methods.mint(quantity);

    let options = {
      from,
      value: (parseFloat(config.unitPrice) * 10 ** 18 * quantity).toString(),
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(e);
        if (e.message.includes("sale is not active")) {
          throw { message: `Sales  has not started yet!` };
        } else {
          throw { message: `Insufficient funds!` };
        }
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  async freeMint(from) {
    let options = {
      from,
    };

    const mintAction = this.contract.methods.freeMint();
    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(e);
        if (e.message.includes("sale is not active")) {
          throw { message: `Sales  has not started yet!` };
        } else if (e.message.includes("caller already minted for free")) {
          throw { message: `You already minted your free nfts` };
        } else {
          throw { message: `Insufficient funds!` };
        }
      });
    options = { ...options, gas: parseInt(1.2 * gas) };
    return mintAction.send(options);
  }

  async getEstimatedGas(quantity, options) {
    return this.contract.methods.mint(quantity).estimateGas(options);
  }

  sign(address) {
    return web3.eth.personal.sign(
      "Hi there! Sign this message to prove you have access to this wallet. This won’t cost you any Ether.",
      address
    );
  }
}

export default new MainContractService();
