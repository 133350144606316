export function countDownTimer(date) {
  var countDownDate = new Date(date).getTime();

  var now = new Date().getTime();

  var distance = countDownDate - now;

  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

  if (distance < 0) {
    return "";
  }

  let final = "";
  if (days > 0) final += days + "d ";
  if (hours > 0) final += hours + "h ";
  if (minutes > 0) final += minutes + "m ";
  if (seconds > 0) final += seconds + "s ";

  return final;
}

export function isReleased(date) {
  var countDownDate = new Date(date).getTime();
  var now = new Date().getTime();
  return countDownDate - now < 0;
}
