<template>
  <div class="container">
    <div class="header text-center">
      <h2>Evolution</h2>
    </div>
    <div class="d-flex justify-content-center">
      <img class="mx-2" :src="'/static/images/all-poop.png'" alt="" srcset="" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;

  h2 {
    width: 100%;
  }
}
</style>
