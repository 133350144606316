import "mosha-vue-toastify/dist/style.css";
import { createToast } from "mosha-vue-toastify";

class Toast {
  error = (title, description) => this.toast(title, description, "danger");
  success = (title, description) => this.toast(title, description, "success");
  warning = (title, description) => this.toast(title, description, "warning");
  info = (title, description) => this.toast(title, description, "info");

  fixedMessage = (title, description) => {
    return createToast(
      {
        title: title,
        description: description,
      },
      {
        timeout: 600000,
        transition: "zoom",
        position: "top-left",
        type: "info",
        hideProgressBar: "false",
        showIcon: "true",
      }
    );
  };

  toast(title, description, type) {
    createToast(
      {
        title: title,
        description: description,
      },
      {
        type: type,
        position: "top-center",
        showIcon: "true",
      }
    );
  }
}

export default {
  install(app) {
    app.config.globalProperties.$toast = new Toast();
  },
};
