export function formatWalletAddress(address) {
  return address !== undefined
    ? `${address.slice(0, 6)}...${address.slice(-4)}`
    : "Disconnect";
}

export function formatBalance(balance) {
  return (balance / 10 ** 18).toFixed(3);
}

export function handleRPCError(error) {
  const parsedError = JSON.stringify(error.message);

  if (parsedError.includes("execution reverted: ")) {
    const rgx = /(?:execution reverted: )(.*)(?=\\n{)/g;
    const rgxResult = rgx.exec(parsedError);
    return Array.isArray(rgxResult) ? rgxResult[0] : rgxResult;
  }

  return "Unknown error";
}
