import { createStore } from "vuex";

export default createStore({
  state: {
    accountAddress: null,
    networkId: null,
    totalSupply: 0,
    isConnected: false,
    walletBalance: 0,
  },
  getters: {
    isConnected(state) {
      return (
        state.accountAddress &&
        state.networkId == process.env.VUE_APP_NETWORK_ID
      );
    },
  },
  mutations: {
    SET_ACCOUNT_ADDRESS(state, payload) {
      state.accountAddress = payload;
    },
    SET_NETWORK_ID(state, payload) {
      state.networkId = payload;
    },
    SET_TOTAL_SUPPLY(state, payload) {
      state.totalSupply = payload;
    },
    SET_WALLET_BALANCE(state, payload) {
      state.walletBalance = payload;
    },
  },
  actions: {},
  modules: {},
});
