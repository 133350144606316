<template>
  <div class="wrapper text-center my-3 mx-2" :class="{ selected: selected }">
    <img
      :src="`https://api.pootopia.io/asset/${id}.png?t=${Date.now()}`"
      alt=""
      srcset=""
    />
    <button v-if="!selected" class="btn">Evolve</button>
    <button v-else class="btn">Remove</button>
  </div>
</template>
<script>
// import axios from "axios";
export default {
  data() {
    return {
      dailyProduction: 0,
      vaultBonus: 0,
    };
  },
  props: {
    id: {
      type: String,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // axios
    //   .get(`${this.config.apiUrl}asset/points/${this.id}`)
    //   .then(async ({ data }) => {
    //     this.dailyProduction = data.dailyProduction;
    //     this.vaultBonus = data.vaultBonus;
    //   })
    //   .catch(() => {});
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  text-decoration: none;
  color: #fff;
  flex: auto;
  align-content: end;
  margin: 0 auto;
  position: relative;
  width: 15%;
  min-width: 180px;
  max-width: 180px;
  min-height: 235px;
  outline: none;
  display: flex;
  border: 5px solid var(--color-alternative);
  flex-flow: wrap;

  &.selected {
    transform: scale(1.1);
  }

  .btn {
    width: 100%;
    cursor: pointer;
    border-radius: 0;
    border-bottom: none;
    border-left: none;
    border-right: none;
  }

  .token-info {
    font-size: 0.8em;
  }

  > img {
    width: 100%;
  }
}
</style>
