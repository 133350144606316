import web3 from "../services/Web3Service";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "../store";
import { createToast } from "mosha-vue-toastify";
import MainContractService from "../services/MainContractService";
const toast = (title, description, type) => {
  createToast(
    {
      title: title,
      description: description,
    },
    {
      type: type,
      position: "top-center",
      showIcon: "true",
    }
  );
};

class Wallet {
  constructor() {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: process.env.VUE_APP_INFURA_ID,
        },
      },
    };

    this._web3Modal = new Web3Modal({
      network: process.env.VUE_APP_NETWORK_NAME,
      cacheProvider: true,
      providerOptions,
    });

    this.connect();
    // setInterval(() => {
    //   this._setTotalSupply();
    // }, 1000);
    // this._setTotalSupply();
  }

  async connect() {
    const provider = await this._web3Modal.connect();

    web3.setProvider(provider);

    provider.on("accountsChanged", this._onAccountsChanged);
    provider.on("chainChanged", this._onChainChanged);

    await Promise.all([this._getCurrentNetworkId(), this._getCurrentAccount()]);
  }

  _setTotalSupply() {
    MainContractService.totalSupply()
      .then((total) => {
        store.commit("SET_TOTAL_SUPPLY", parseInt(total));
      })
      .catch();
  }

  disconnect() {
    this._web3Modal.clearCachedProvider();
  }

  async _getCurrentNetworkId() {
    const networkId = await web3.eth.getChainId();

    if (networkId == parseInt(process.env.VUE_APP_NETWORK_ID)) {
      store.commit("SET_NETWORK_ID", networkId);
    } else {
      toast(
        "The selected network is invalid.",
        "Please select Ethereum mainnet and try again.",
        "danger"
      );
    }
  }

  async _getCurrentAccount() {
    const accounts = await web3.eth.getAccounts();
    let balance = await web3.eth.getBalance(accounts[0]);
    balance = (parseInt(balance) / 10 ** 18).toFixed(4);
    if (accounts.length) {
      store.commit("SET_ACCOUNT_ADDRESS", accounts[0]);
      store.commit("SET_WALLET_BALANCE", balance);
    }
  }

  _onAccountsChanged(accounts) {
    if (accounts.length) {
      store.commit("SET_ACCOUNT_ADDRESS", accounts[0]);
    } else {
      store.commit("SET_ACCOUNT_ADDRESS", null);
      this.disconnect();
    }
  }

  _onChainChanged(networkId) {
    if (networkId == parseInt(process.env.VUE_APP_NETWORK_ID)) {
      store.commit("SET_NETWORK_ID", networkId);
    } else {
      store.commit("SET_NETWORK_ID", null);
      toast(
        "The selected network is invalid.",
        "Please select Ethereum mainnet and try again.",
        "danger"
      );
    }
  }
}

export default {
  install(app) {
    app.config.globalProperties.$wallet = new Wallet();
  },
};
