<template>
  <div class="m-auto text-center">
    <div>
      <img src="/static/images/Fav.png" alt="" />
    </div>
    <div>Loading{{ dots[0] }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dots: [".", "..", "..."],
      interval: null,
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.dots = this.dots.slice(1).concat(this.dots[0]);
    }, 300);
  },
  unmounted() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
img {
  width: 70px;
  aspect-ratio: 1;
  animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1.3);
  }
}
</style>
