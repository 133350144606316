import BaseContractService from "./BaseContractService";
import abi from "../abi/flush.json";
import config from "../config";
import Web3 from "web3";
// import web3 from "./Web3Service";
// import { createToast } from "mosha-vue-toastify";
// const toast = (title, description, type) => {
//   createToast(
//     {
//       title: title,
//       description: description,
//     },
//     {
//       type: type,
//       position: "top-center",
//       showIcon: "true",
//     }
//   );
// };

class MainContractService extends BaseContractService {
  constructor() {
    super(config.flushContractAddress, abi);
  }

  allowance(account, spender) {
    return this.contract.methods.allowance(account, spender).call();
  }

  async permit(owner, spender, value, deadline, v, r, s) {
    const mintAction = this.contract.methods.permit(
      owner,
      spender,
      value,
      deadline,
      v,
      r,
      s
    );

    let options = {
      from: spender,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        throw { message: e.message };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  async permitHasError(owner, spender, value, deadline, v, r, s) {
    let error = false;

    const mintAction = this.contract.methods.permit(
      owner,
      spender,
      value,
      deadline,
      v,
      r,
      s
    );

    let options = {
      from: spender,
    };

    await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch(() => {
        error = true;
      });

    return error;
  }

  async approve(account, spender, amount) {
    const value = Web3.utils.toWei(amount.toString(), "ether");
    console.log(value);
    const mintAction = this.contract.methods.approve(spender, value);

    let options = {
      from: account,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        throw { message: e.message };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  balanceOf(account) {
    return this.contract.methods.balanceOf(account).call();
  }

  async transferFrom(sender, recipient, amount) {
    const mintAction = this.contract.methods.transferFrom(
      sender,
      recipient,
      amount
    );

    let options = {
      from: recipient,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        throw { message: e.message };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }

  addFlush() {
    const tokenAddress = process.env.VUE_APP_FLUSH_CONTRACT_ADDRESS;
    const tokenSymbol = "FLUSH";
    const tokenDecimals = 18;
    const tokenImage = `${window.location.origin}/static/images/flush-token-golden.png`;

    window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });
  }
}

export default new MainContractService();
