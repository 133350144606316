import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Wallet from "./plugins/Wallet";
import Toast from "./plugins/Toast";
import config from "./config";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import VideoBackground from "vue-responsive-video-background-player";

const configMixin = {
  computed: {
    config() {
      return config;
    },
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(Wallet)
  .use(Toast)
  .component("infinite-loading", InfiniteLoading)
  .component("video-background", VideoBackground)
  .mixin(configMixin)
  .mount("#app");
