import BaseContractService from "./BaseContractService";
import abi from "../abi/evolve.json";
import config from "../config";
import { handleRPCError } from "../utils/wallet.helper";
// import { createToast } from "mosha-vue-toastify";
// const toast = (title, description, type) => {
//   createToast(
//     {
//       title: title,
//       description: description,
//     },
//     {
//       type: type,
//       position: "top-center",
//       showIcon: "true",
//     }
//   );
// };

class EvolveContractService extends BaseContractService {
  constructor() {
    super(config.evolveContractAddress, abi);
  }

  nextLevelPrice(id) {
    return this.contract.methods.nextLevelPrice(id).call();
  }

  async evolve(id, from) {
    const mintAction = this.contract.methods.evolve(id);

    let options = {
      from,
    };

    const gas = await mintAction
      .estimateGas(options)
      .then((e) => e)
      .catch((e) => {
        console.log(handleRPCError(e));
        throw { message: handleRPCError(e) };
      });
    options = { ...options, gas: parseInt(1.2 * gas) };

    return mintAction.send(options);
  }
}

export default new EvolveContractService();
