<template>
  <div class="header">
    <div class="container d-flex justify-content-between">
      <div class="logo">
        <img src="/static/images/logo.png" alt="" srcset="" />
      </div>
      <div class="wallet">
        <WalletButton />
      </div>
    </div>
  </div>
</template>

<script>
import WalletButton from "./WalletButton.vue";
import { isReleased } from "../utils/time.helper";

export default {
  data() {
    return {
      showGallery: false,
      timerInt: {},
    };
  },
  components: {
    WalletButton,
  },
  mounted() {
    this.timerInt = setInterval(() => {
      this.showGallery = isReleased(this.config.releaseSpecial);
      if (this.showGallery) clearInterval(this.timerInt);
    }, 1000);
  },
  unmounted() {
    clearInterval(this.timerInt);
  },
};
</script>

<style lang="scss" scoped>
.logo img {
  width: 160px;
  margin: 10px 0;
}

@media screen and (max-width: 768px) {
  .logo {
    display: flex;
    justify-content: center;
  }

  .logo img {
    width: 120px;
  }
}

@media screen and (max-width: 480px) {
  .container {
    display: block !important;
  }

  .header {
    height: 200px !important;
  }
}

.container {
  padding: 15px !important;
}

.header {
  height: 135px;
}
</style>
