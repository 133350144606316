export default {
  baseUrl: process.env.VUE_APP_BASE_URL,
  imageUrl: process.env.VUE_APP_IMAGE_URL,
  mainContractAddress: process.env.VUE_APP_MAIN_CONTRACT_ADDRESS,
  stakingContractAddress: process.env.VUE_APP_STAKING_CONTRACT_ADDRESS,
  flushContractAddress: process.env.VUE_APP_FLUSH_CONTRACT_ADDRESS,
  evolveContractAddress: process.env.VUE_APP_EVOLVE_CONTRACT_ADDRESS,
  networkId: process.env.VUE_APP_NETWORK_ID,
  networkName: process.env.VUE_APP_NETWORK_NAME,
  totalUnits: process.env.VUE_APP_TOTAL_UNITS,
  unitPrice: process.env.VUE_APP_MINT_PRICE,
  rpc: process.env.VUE_APP_RPC,
  freeMints: process.env.VUE_APP_FREE_MINTS,
  apiUrl: process.env.VUE_APP_API_URL,
};
